Vue.component('suggestionComponent', {
    methods:{
        goBack:function(){
            this.$router.go(-1);
        }
    },
    template: `<div class="items-view">
                  <changePasswordModalComponent></changePasswordModalComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                            <!--<registerComponent></registerComponent>-->
                            <loginComponent></loginComponent>
                            <div class="row">
                              <div class="left sticky-component items-category-tree">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                              </div>                                
                              <div class="col m8 s12">
                                  <template v-if="$store.getters.getAppState=='ready'">
                                        <suggestionListComponent></suggestionListComponent>
                                  </template>
                                  <template v-else>               
                                        <div class="container center section white" style="min-height: 60%;" >
                                                <h3>{{tr('Page loading please wait')}}</h3>
                                        </div>
                                  </template>
                              </div>
                              <div class="col m2 s12 sticky-component right">
                                  <userSectionComponent></userSectionComponent>
                                  <carComponent></carComponent>
                              </div>
                            </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`
});
